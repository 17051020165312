import React from 'react';

import {StyledHeader, StyledHeaderImage} from './_styled';

type HeaderProps = {
  onDragonClick?: () => void;
  children?: string | JSX.Element | JSX.Element[] | any[];
};

const Header = ({onDragonClick = () => false, children}: HeaderProps) => {
  return (
    <StyledHeader>
      <StyledHeaderImage onClick={onDragonClick} />
      {children}
    </StyledHeader>
  );
};

export default Header;
