import React, {ChangeEvent, useState} from 'react';

import PlayerNameInput from './PlayerNameInput';
import Button from '../common/Button';
import {MAX_PLAYER_NAME_LENGTH, Player} from '../state/playersReducer';
import {StyledPullCenter} from '../common/_styled';

import {
  StyledGameSetupWrapper,
  StyledPlayersList,
  StyledPlayersListBox,
  StyledTeams,
  StyledTeamName,
  StyledInput
} from './_styled';

type GameSetupProps = {
  onSetupDone: (players: Player[]) => void;
};

/**
 * View for entering four player names and pairing them to two teams.
 */
const GameSetup = ({onSetupDone}: GameSetupProps) => {
  const [players, setPlayers] = useState<Player[]>([]);

  return (
    <StyledGameSetupWrapper>
      <StyledPlayersList>
        {players.map((pl, index) => (
          <StyledPlayersListBox key={`playerName:${index}`} index={index}>
            {/* MOVE-UP Button */}
            {(index === 2 || index === 3) && (
              <Button
                style={{gridColumnStart: 'center', gridRowStart: 'top', justifySelf: 'center'}}
                onClick={() => onMovePlayerUp(index)}
              >
                <i className="icon-angle-up" />
              </Button>
            )}

            {/* MOVE-LEFT Button */}
            {(index === 1 || index === 3) && (
              <Button
                style={{gridColumnStart: 'left', gridRowStart: 'center'}}
                onClick={() => onMovePlayerLeft(index)}
              >
                <i className="icon-angle-left" />
              </Button>
            )}

            <div style={{gridColumnStart: 'center', gridRowStart: 'center'}}>
              <StyledInput
                type="text"
                value={pl.name}
                onChange={(e) => onPlayerNameEdit(e, index)}
              />
            </div>

            {/* MOVE-RIGHT Button */}
            {(index === 0 || index === 2) && (
              <Button
                style={{gridColumnStart: 'right', gridRowStart: 'center'}}
                onClick={() => onMovePlayerRight(index)}
              >
                <i className="icon-angle-right" />
              </Button>
            )}

            {/* MOVE-DOWN Button */}
            {(index === 0 || index === 1) && (
              <Button
                style={{gridColumnStart: 'center', gridRowStart: 'bottom', justifySelf: 'center'}}
                onClick={() => onMovePlayerDown(index)}
              >
                <i className="icon-angle-down" />
              </Button>
            )}
          </StyledPlayersListBox>
        ))}
      </StyledPlayersList>

      {players.length < 4 && <PlayerNameInput playerIndex={players.length} onAdd={onAddPlayer} />}

      {players.length > 3 && (
        <div>
          <StyledTeams>
            <StyledTeamName>
              {players[0].name} & {players[3].name}
            </StyledTeamName>
            <div>vs.</div>
            <StyledTeamName>
              {players[1].name} & {players[2].name}
            </StyledTeamName>
          </StyledTeams>
          <StyledPullCenter>
            <Button onClick={() => onSetupDone(players)} primary={true}>
              Los!
            </Button>
          </StyledPullCenter>
        </div>
      )}
    </StyledGameSetupWrapper>
  );

  function onAddPlayer(playerName: string, playerIndex: number) {
    const newPlayerObject = {
      index: playerIndex,
      name: playerName
    };

    setPlayers([...players, newPlayerObject]);
  }

  function onPlayerNameEdit(e: ChangeEvent<HTMLInputElement>, playerIndex: number) {
    const playerName = e.target.value.substring(0, MAX_PLAYER_NAME_LENGTH);
    const modifiedPlayersArray = players.map((pl, i) => {
      if (i === playerIndex) {
        return {
          ...pl,
          name: playerName
        };
      } else {
        return pl;
      }
    });
    setPlayers(modifiedPlayersArray);
  }

  function onMovePlayerLeft(currentPlayerIndex: number) {
    if (currentPlayerIndex !== 1 && currentPlayerIndex !== 3) {
      return;
    }

    let modifiedPlayersArray;
    if (currentPlayerIndex === 1) {
      modifiedPlayersArray = [players[1], players[0], players[2], players[3]];
    } else if (currentPlayerIndex === 3) {
      modifiedPlayersArray = [players[0], players[1], players[3], players[2]];
    } else {
      throw new Error('cannot');
    }

    setAfterMovePlayer(modifiedPlayersArray);
  }

  function onMovePlayerRight(currentPlayerIndex: number) {
    if (currentPlayerIndex !== 0 && currentPlayerIndex !== 2) {
      return;
    }

    let modifiedPlayersArray;
    if (currentPlayerIndex === 0) {
      modifiedPlayersArray = [players[1], players[0], players[2], players[3]];
    } else if (currentPlayerIndex === 2) {
      modifiedPlayersArray = [players[0], players[1], players[3], players[2]];
    } else {
      throw new Error('cannot');
    }

    setAfterMovePlayer(modifiedPlayersArray);
  }

  function onMovePlayerUp(currentPlayerIndex: number) {
    if (currentPlayerIndex !== 2 && currentPlayerIndex !== 3) {
      return;
    }

    let modifiedPlayersArray;
    if (currentPlayerIndex === 2) {
      modifiedPlayersArray = [players[2], players[1], players[0], players[3]];
    } else if (currentPlayerIndex === 3) {
      modifiedPlayersArray = [players[0], players[3], players[2], players[1]];
    } else {
      throw new Error('cannot');
    }

    setAfterMovePlayer(modifiedPlayersArray);
  }

  function onMovePlayerDown(currentPlayerIndex: number) {
    if (currentPlayerIndex !== 0 && currentPlayerIndex !== 1) {
      return;
    }

    let modifiedPlayersArray;
    if (currentPlayerIndex === 0) {
      modifiedPlayersArray = [players[2], players[1], players[0], players[3]];
    } else if (currentPlayerIndex === 1) {
      modifiedPlayersArray = [players[0], players[3], players[2], players[1]];
    } else {
      throw new Error('cannot');
    }

    setAfterMovePlayer(modifiedPlayersArray);
  }

  function setAfterMovePlayer(modifiedPlayersArray: Player[]) {
    // make sure that the "index" property is also set correctly
    modifiedPlayersArray = modifiedPlayersArray.map((pl, newIndex) => ({...pl, index: newIndex}));
    setPlayers(modifiedPlayersArray);
  }
};

export default GameSetup;
