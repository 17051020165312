import getBrowserHistory from './getBrowserHistory';

const history = getBrowserHistory();

/**
 * Returns the game id from the current URL (window.location.pathname).
 * If the url matches pattern   https://domain/game-id   where game-id  is at least 3 chars and does not contain a forward slash
 */
export default function getGameIdFromUrl(): string {
  const path = history.location?.pathname?.substring(1);

  if (!path || path.length < 3) {
    return '';
  }

  if (path.includes('/')) {
    return '';
  }

  return path;
}

// export function listenToGameIdUrlChange(listener: (gameId: string) => void) {
//   history.listen(({action, location}) => {
//     listener(getGameIdFromUrl());
//   });
// }
