import styled from 'styled-components/macro';

import {COL_BACKGROUND_DARK_TWO, COL_BORDER} from '../colors';
import headerImage from '../resources/dragon_on_red_100h.png';

export const HEADER_HEIGHT = 49;

export const StyledHeader = styled.div`
  background: ${COL_BACKGROUND_DARK_TWO};
  padding: 4px;
  flex-grow: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COL_BORDER};
`;

export const StyledHeaderImage = styled.div`
  background: url('${headerImage}') no-repeat;
  height: ${HEADER_HEIGHT - 9}px;
  width: ${Math.floor(((HEADER_HEIGHT - 9) / 3) * 2)}px;
  background-size: contain;
`;
