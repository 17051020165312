import {AnyAction} from 'redux';

import {
  AT_EXITED,
  AT_GREAT_TICHU_SAID,
  AT_PLAYER_FINISHED,
  AT_ROUND_FINISHED,
  AT_TICHU_SAID,
  AT_UNDO
} from './actions';

export interface UndoItem {
  actionType: string;
  payload: any;
}

export default function undoReducer(state: UndoItem[] = [], action: AnyAction): UndoItem[] {
  switch (action.type) {
    case AT_TICHU_SAID:
    case AT_GREAT_TICHU_SAID:
    case AT_PLAYER_FINISHED: {
      return [
        ...state,
        {
          actionType: action.type,
          payload: action.payload
        }
      ];
    }

    case AT_UNDO: {
      const modifiedState = [...state];

      if (modifiedState.length < 1) {
        throw new Error('Cannot undo action. no actions to undo');
      }

      const lastAction = modifiedState.pop();

      if (lastAction?.actionType === action.payload.undoActionType) {
        return modifiedState;
      } else {
        throw new Error(
          `Cannot undo action "${action.payload.undoActionType}". Last action is "${lastAction?.actionType}"`
        );
      }
    }

    case AT_EXITED:
    case AT_ROUND_FINISHED: {
      return [];
    }

    default:
      return state;
  }
}
