import React, {useEffect, useState} from 'react';
import format from 'date-fns/format';

import Button from '../common/Button';
import Header from '../header/Header';
import {clearPersistedState, GameListItem, listPersistedGames} from '../utils/persistor';
import {StyledGameList, StyledLandingWrapper, StyledMainArea, StyledMiniButton} from './_styled';
import {StyledPullCenter} from '../common/_styled';
import {normalizeDateOrTs} from '../utils/dateTimeUtil';

type LandingProps = {
  onNewGame: () => void;
};

const Landing = ({onNewGame}: LandingProps) => {
  const [gameList, setGameList] = useState<GameListItem[]>([]);

  useEffect(() => {
    setGameList(listPersistedGames());
  }, []);

  return (
    <StyledLandingWrapper>
      <Header />
      <StyledMainArea>
        <Button onClick={onNewGame} primary={true}>
          Neus Spiel
        </Button>
      </StyledMainArea>

      {gameList && gameList.length > 0 && (
        <StyledPullCenter>
          <StyledGameList>
            {gameList.map((g) => (
              <li key={g.id}>
                <a href={`/${g.id}`}>
                  {format(normalizeDateOrTs(g.gameStartTimestamp), 'EEE MMM dd yyyy, HH:mm')} (
                  {g.roundCount})
                </a>
              </li>
            ))}
          </StyledGameList>
        </StyledPullCenter>
      )}

      <StyledPullCenter>
        <StyledMiniButton onClick={onClearClick}>Lokali Date lösche!</StyledMiniButton>
      </StyledPullCenter>
    </StyledLandingWrapper>
  );

  function onClearClick() {
    clearPersistedState();
    setGameList([]);
  }
};

export default Landing;
