import React from 'react';

import Button from '../common/Button';
import TichuButton from './TichuButton';
import {getPlayerRoundStatus} from '../state/selectors';
import {Player} from '../state/playersReducer';
import {Round} from '../state/roundsReducer';

import {
  StyledPlayerTileWrapper,
  StyledPlayerName,
  StyledTichuButtonWrapper,
  StyledFinisherPlace,
  StyledFirstFinisherBadge,
  StyledFirstFinisherNumber,
  StyledFinishStatus
} from './_styled';

type PlayerTileProps = {
  player: Player;
  onSaidTichu: () => void;
  onSaidGreatTichu: () => void;
  onFinish: () => void;
  currentRound: Round;
};

const PlayerTile = ({
  player,
  currentRound,
  onSaidTichu,
  onSaidGreatTichu,
  onFinish
}: PlayerTileProps) => {
  const somebodyFinished = currentRound.finishers.length > 0;
  const plStatus = getPlayerRoundStatus(currentRound, player.index);

  return (
    <StyledPlayerTileWrapper x-player-index={player.index}>
      <StyledPlayerName>{player.name}</StyledPlayerName>

      <StyledTichuButtonWrapper>
        <TichuButton
          onClick={onSaidTichu}
          canClick={!plStatus.hasSaidTichu && !plStatus.hasSaidGreatTichu && !somebodyFinished}
          clicked={plStatus.hasSaidTichu}
          failed={plStatus.hasFailedTichu}
          succeeded={plStatus.hasSucceededTichu}
          text="Tichu!"
          textClicked="Tichu aagseit!"
        />

        <TichuButton
          onClick={onSaidGreatTichu}
          canClick={!plStatus.hasSaidTichu && !plStatus.hasSaidGreatTichu && !somebodyFinished}
          clicked={plStatus.hasSaidGreatTichu}
          failed={plStatus.hasFailedGreatTichu}
          succeeded={plStatus.hasSucceededGreatTichu}
          text="Grosses Tichu!"
          textClicked="Grosses Tichu aagseit!"
        />
      </StyledTichuButtonWrapper>

      {!plStatus.hasFinished && <Button onClick={onFinish}>Fertig</Button>}

      {plStatus.hasFinished && (
        <>
          <StyledFinisherPlace>
            <StyledFirstFinisherNumber position={finisherPlacePosition(plStatus.finisherIndex)} />
            <div>{finisherPlaceSpeaking(plStatus.finisherIndex)}</div>
            {plStatus.finisherIndex === 0 && <StyledFirstFinisherBadge />}
          </StyledFinisherPlace>
          <StyledFinishStatus>
            {plStatus.hasSucceededGreatTichu && <span>Grosses Tichu gmacht! +200</span>}
            {plStatus.hasSucceededTichu && <span>Tichu gmacht! +100</span>}
            {plStatus.hasFailedGreatTichu && (
              <span>Grosses Tichu aagseit, aber nöd gmacht! -200</span>
            )}
            {plStatus.hasFailedTichu && <span>Tichu aagseit, aber nöd gmacht! -100</span>}
          </StyledFinishStatus>
          <StyledFinishStatus>
            {plStatus.isDoubleWin && <span>Doppelsieg!! =200</span>}
          </StyledFinishStatus>
        </>
      )}
    </StyledPlayerTileWrapper>
  );
};

export default PlayerTile;

const finisherPlaceSpeaking = (finisherIndex: number): string => {
  const finisherPlacesSpeaking = ['Erstä', 'Zweitä', 'Drittä', 'Letschtä'];
  return finisherPlacesSpeaking[finisherIndex];
};
const finisherPlacePosition = (finisherIndex: number): number => {
  const finisherPlacesPosition = [0, -20, -46, -67];
  return finisherPlacesPosition[finisherIndex];
};
