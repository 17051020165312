import {createStore, Store, applyMiddleware, AnyAction} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';

import {TichuState} from './state';
import rootReducer from './rootReducer';
import {setPersistedState} from '../utils/persistor';

/**
 * on every action, call "setPersistedState" after action was reduced
 * @param store
 */
const persistingMiddleware =
  (store: any) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    next(action);
    setPersistedState(store.getState());
  };

/**
 *
 */
export default function configureStore(initialState: TichuState): Store<TichuState> {
  const composeEnhancers = composeWithDevTools({});

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(persistingMiddleware))
  );
}
