import React from 'react';

import Button from '../common/Button';
import {StyledClickedTichuButton} from './_styled';

type TichuButtonProps = {
  text: string;
  textClicked: string;
  clicked: boolean;
  canClick: boolean;
  failed: boolean;
  succeeded: boolean;
  onClick: () => void;
};

const TichuButton = ({
  text,
  textClicked,
  clicked,
  canClick,
  failed,
  succeeded,
  onClick
}: TichuButtonProps) => {
  if (clicked) {
    return (
      <StyledClickedTichuButton failed={failed} succeeded={succeeded}>
        {textClicked}
      </StyledClickedTichuButton>
    );
  }

  if (canClick && !clicked) {
    return <Button onClick={onClick}>{text}</Button>;
  }

  return (
    <Button onClick={() => false} disabled={true}>
      {text}
    </Button>
  );
};

export default TichuButton;
