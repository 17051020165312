import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createGlobalStyle} from 'styled-components';

import App from './App';
import configureStore from './state/configureStore';
import {INITIAL_STATE} from './state/state';
import {COL_BACKGROUND_DARK, COL_FONT} from './colors';

import './resources/fontello/css/tichu.css';
import {getPersistedStateMatchingUrlGameId} from './utils/persistor';

const GlobalTichuxStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
  }

  div, span {
    user-select: none;
  }


  html {
    background: ${COL_BACKGROUND_DARK};
    color: ${COL_FONT};
  }

`;

const reduxStore = configureStore(getPersistedStateMatchingUrlGameId() || INITIAL_STATE);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalTichuxStyle />
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </React.StrictMode>
);
