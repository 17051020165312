import styled from 'styled-components/macro';
import {COL_BORDER, COL_TICHU_FAILED_BORDER, COL_TICHU_SUCCEEDED_BORDER} from '../colors';

export const StyledFlexTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

export const StyledFlexTableCell = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;

  /* first three cells */

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    border-bottom: 1px solid ${COL_BORDER};
  }

  &:nth-child(1) {
    > h2 {
      text-align: center;
    }
  }

  /* every third cell starting with 1: 1,4,7,...*/

  &:nth-child(3n-2) {
    width: 6%;
    border-right: 1px solid ${COL_BORDER};
    padding: 8px 0;

    > h3 {
      text-align: center;
    }
  }

  /* every third cell starting with 2:  2,5,8,...*/
  /* every third cell starting with 3:  3,6,9,...*/
  /* these are the cells for team one */

  &:nth-child(3n-1),
  &:nth-child(3n) {
    width: 47%;
    padding: 8px 4px 8px 16px;
  }
`;

export const StyledTeamRoundCell = styled(StyledFlexTableCell)`
  > div {
    &.tch-failed {
      color: ${COL_TICHU_FAILED_BORDER};
    }

    &.tch-succeeded {
      color: ${COL_TICHU_SUCCEEDED_BORDER};
    }

    > h4 {
      margin-bottom: 4px;
    }

    > span,
    > i {
      font-size: 0.9rem;
    }
  }
`;
