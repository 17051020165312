import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import PlayerTile from './PlayerTile';
import Header from '../header/Header';
import Menu from '../menu/Menu';
import GameSetup from './GameSetup';
import {
  getCurrentRound,
  getCurrentRoundNumber,
  getPlayers,
  getTotalScore,
  getUndoItems
} from '../state/selectors';
import {
  aPlayerSaysTichu,
  aPlayerSaysGreatTichu,
  aPlayerFinishes,
  aStartFirstRound,
  aUndo
} from '../state/actions';
import Button from '../common/Button';
import {Player} from '../state/playersReducer';
import {TEAM} from '../state/roundsReducer';

import {StyledPlayersWrapper} from './_styled';
import {StyledViewWrapper} from '../common/_styled';

type GameProps = {
  gameId: string;
  onSwitchToRoundScoreView: () => void;
  onSwitchToGameLogView: () => void;
};

const Game = ({gameId, onSwitchToRoundScoreView, onSwitchToGameLogView}: GameProps) => {
  const dispatch = useDispatch();
  const currentRound = useSelector(getCurrentRound);
  const undoItems = useSelector(getUndoItems);
  const roundNumber = useSelector(getCurrentRoundNumber);
  const players = useSelector(getPlayers);
  const totalScore = useSelector(getTotalScore);

  const [menuShown, setMenuShown] = useState(false);

  const currentRoundFinished = currentRound && currentRound.finishers.length === 4;
  const gameStarted = !!currentRound;

  return (
    <StyledViewWrapper x-game-id={gameId}>
      {menuShown && <Menu onSwitchToGameLogView={onSwitchToGameLogView} />}
      <Header onDragonClick={() => setMenuShown(!menuShown && gameStarted)}>
        <Button onClick={onUndo} disabled={undoItems.length < 1}>
          <i className="icon-ccw" />
        </Button>
        <div>{gameStarted && totalScore[TEAM.one]}</div>
        <div>{roundNumber > 0 && `Rundi:${roundNumber}`}</div>
        <div>{gameStarted && totalScore[TEAM.two]}</div>

        <Button primary={true} onClick={onSwitchToRoundScoreView} disabled={!currentRoundFinished}>
          Pünkt
        </Button>
      </Header>

      {!gameStarted && <GameSetup onSetupDone={onGameSetupDone} />}

      {gameStarted && (
        <StyledPlayersWrapper>
          {players.map((pl, index) => (
            <PlayerTile
              key={`playerName:${index}`}
              player={pl}
              currentRound={currentRound}
              onSaidTichu={() => onPlayerSaidTichu(index)}
              onSaidGreatTichu={() => onPlayerSaidGreatTichu(index)}
              onFinish={() => onPlayerFinish(index)}
            />
          ))}
        </StyledPlayersWrapper>
      )}
    </StyledViewWrapper>
  );

  function onGameSetupDone(players: Player[]) {
    dispatch(aStartFirstRound(players));
  }

  function onPlayerSaidTichu(playerIndex: number) {
    dispatch(aPlayerSaysTichu(playerIndex));
  }

  function onPlayerSaidGreatTichu(playerIndex: number) {
    dispatch(aPlayerSaysGreatTichu(playerIndex));
  }

  function onPlayerFinish(playerIndex: number) {
    dispatch(aPlayerFinishes(playerIndex));
  }

  function onUndo() {
    const lastItem = undoItems[undoItems.length - 1];
    dispatch(aUndo(lastItem.actionType, lastItem.payload));
  }
};

export default Game;
