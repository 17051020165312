import getGameIdFromUrl from '../utils/getGameIdFromUrl';
import {Round} from './roundsReducer';
import {Player} from './playersReducer';
import {UndoItem} from './undoReducer';
import {GameMeta} from './gameMetaReducer';

export interface TichuState {
  gameId: string;
  gameMeta: GameMeta;
  players: Player[];
  rounds: Round[];
  undo: UndoItem[];
}

export const INITIAL_STATE: TichuState = {
  gameId: getGameIdFromUrl(),
  gameMeta: {gameStartTimestamp: 0},
  players: [],
  rounds: [],
  undo: []
};
