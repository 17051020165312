import {AnyAction} from 'redux';

import {AT_EXITED, AT_NEWGAME_ID_SET} from './actions';

export default function gameIdReducer(state: string = '', action: AnyAction): string {
  switch (action.type) {
    case AT_NEWGAME_ID_SET: {
      return action.payload.gameId;
    }

    case AT_EXITED: {
      return '';
    }

    default:
      return state;
  }
}
