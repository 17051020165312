import React from 'react';
import {useDispatch} from 'react-redux';

import {aExit} from '../state/actions';
import {StyledMenu} from './_styled';

type MenuProps = {
  onSwitchToGameLogView: () => void;
};

const Menu = ({onSwitchToGameLogView}: MenuProps) => {
  const dispatch = useDispatch();

  return (
    <StyledMenu>
      <ul>
        <li onClick={onSwitchToGameLogView}>Verlauf</li>
        <li onClick={onExitGame}>Spiel beändä</li>
        <li>
          <a href="/Tichu_Regel.pdf" target="_blank" rel="noopener noreferrer">
            Tichu Reglä
          </a>
        </li>
      </ul>
    </StyledMenu>
  );

  function onExitGame() {
    dispatch(aExit());
  }
};

export default Menu;
