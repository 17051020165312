import {TeamRoundStatus} from '../state/selectors';

export const STICH_POINT_TOTAL = 100;

export const DOUBLE_WIN_POINTS = 200;

/**
 *
 * @param teamRoundStatus
 * @param stichPoints
 */
export function calcTotalRoundScore(teamRoundStatus: TeamRoundStatus, stichPoints: number) {
  let totalRoundScore = 0;
  if (teamRoundStatus.hasSucceededTichu) {
    totalRoundScore += 100;
  }
  if (teamRoundStatus.hasFailedTichu) {
    totalRoundScore -= 100;
  }
  if (teamRoundStatus.hasSucceededGreatTichu) {
    totalRoundScore += 200;
  }
  if (teamRoundStatus.hasFailedGreatTichu) {
    totalRoundScore -= 200;
  }

  if (teamRoundStatus.isDoubleWin) {
    totalRoundScore += 200;
  } else {
    totalRoundScore += stichPoints;
  }
  return totalRoundScore;
}
