import React, {useState} from 'react';

import Button from '../common/Button';
import {MAX_PLAYER_NAME_LENGTH} from '../state/playersReducer';
import {StyledPlayerNameInputWrapper, StyledInput} from './_styled';

type PlayerNameInputProps = {
  playerIndex: number;
  onAdd: (playerName: string, playerIndex: number) => void;
};

const PlayerNameInput = ({playerIndex, onAdd}: PlayerNameInputProps) => {
  const [currentPlayerName, setCurrentPlayerName] = useState('');

  return (
    <StyledPlayerNameInputWrapper>
      <StyledInput
        type="text"
        value={currentPlayerName}
        onChange={(e) => setCurrentPlayerName(e.target.value.substring(0, MAX_PLAYER_NAME_LENGTH))}
        placeholder={`Player ${playerIndex + 1}`}
        onKeyPress={onKeyPress}
      />
      <Button onClick={onAddPlayer}>
        <i className="icon-plus" />
      </Button>
    </StyledPlayerNameInputWrapper>
  );

  function onAddPlayer() {
    if (currentPlayerName && currentPlayerName.length > 0) {
      onAdd(currentPlayerName, playerIndex);
      setCurrentPlayerName('');
    }
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      onAddPlayer();
    }
  }
};

export default PlayerNameInput;
