import {combineReducers} from 'redux';

import playersReducer from './playersReducer';
import roundsReducer from './roundsReducer';
import gameIdReducer from './gameIdReducer';
import undoReducer from './undoReducer';
import gameMetaReducer from './gameMetaReducer';

const rootReducer = combineReducers({
  players: playersReducer,
  rounds: roundsReducer,
  gameId: gameIdReducer,
  undo: undoReducer,
  gameMeta: gameMetaReducer
});

export default rootReducer;
