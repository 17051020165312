import {AnyAction} from 'redux';

import {AT_FIRST_ROUND_STARTED} from './actions';

export interface GameMeta {
  gameStartTimestamp: number; // unix timestamp in seconds
}

export default function gameMetaReducer(
  state: GameMeta = {gameStartTimestamp: 0},
  action: AnyAction
): GameMeta {
  switch (action.type) {
    case AT_FIRST_ROUND_STARTED: {
      return {
        ...state,
        gameStartTimestamp: Math.floor(Date.now() / 1000)
      };
    }

    default:
      return state;
  }
}
