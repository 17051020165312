import {BrowserHistory, createBrowserHistory, createMemoryHistory} from 'history';

let historyInstance: BrowserHistory;

export default function getBrowserHistory() {
  if (!historyInstance) {
    historyInstance =
      typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory();
  }

  return historyInstance;
}
