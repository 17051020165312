import {AnyAction} from 'redux';

import {
  AT_EXITED,
  AT_FIRST_ROUND_STARTED,
  AT_GREAT_TICHU_SAID,
  AT_PLAYER_FINISHED,
  AT_ROUND_FINISHED,
  AT_TICHU_SAID,
  AT_UNDO
} from './actions';
import {getTeamRoundStatus, hasRoundDoublewin} from './selectors';
import {calcTotalRoundScore} from '../utils/gameRuleHelper';

export enum TEAM {
  one = 1,
  two = 2
}

export type RoundScore = {[key in TEAM]: number};

export interface Round {
  roundNumber: number; // number of this round. starting with 1
  tichuSaid: number[]; // indices of players that said "Tichu" in this round
  greatTichuSaid: number[]; // indices of players that said "Great Tichu" in this round
  finishers: number[]; // indices of players in the order of finished (at the end of the round, all four player-indices)
  score: RoundScore; // contains the total score of this round
  stichPoints: RoundScore; // contains the stich points of this round (if one team has double win, these points are also included here!)
}

const getPristineRoundObject = (roundNumber: number): Round => ({
  roundNumber,
  tichuSaid: [],
  greatTichuSaid: [],
  finishers: [],
  score: {
    [TEAM.one]: 0,
    [TEAM.two]: 0
  },
  stichPoints: {
    [TEAM.one]: 0,
    [TEAM.two]: 0
  }
});
/**
 *
 * @param state
 * @param action
 */
export default function roundsReducer(state: Round[] = [], action: AnyAction): Round[] {
  switch (action.type) {
    case AT_FIRST_ROUND_STARTED: {
      return [getPristineRoundObject(1)];
    }

    case AT_TICHU_SAID: {
      throwIfInvalidPlayerIndex(action.payload.playerIndex);
      throwIfSomebodyFinished(state);

      return modifyCurrentRound(state, (currentRound) => ({
        ...currentRound,
        tichuSaid: [...currentRound.tichuSaid, action.payload.playerIndex]
      }));
    }

    case AT_GREAT_TICHU_SAID: {
      throwIfInvalidPlayerIndex(action.payload.playerIndex);
      throwIfSomebodyFinished(state);

      return modifyCurrentRound(state, (currentRound) => ({
        ...currentRound,
        greatTichuSaid: [...currentRound.greatTichuSaid, action.payload.playerIndex]
      }));
    }

    case AT_PLAYER_FINISHED: {
      throwIfInvalidPlayerIndex(action.payload.playerIndex);

      return modifyCurrentRound(state, (currentRound) => {
        const finishers = [...currentRound.finishers, action.payload.playerIndex];

        if (
          finishers.length === 3 || // the third player finished
          (finishers.length === 2 && hasRoundDoublewin({...currentRound, finishers})) // the second player finished and it's a Doppelsieg
        ) {
          // we now have either three finishers
          // or it's a doublewin (Doppelsieg)
          // we add all players to "finishers" that are not yet in
          [0, 1, 2, 3].forEach((playerIndex) => {
            if (!finishers.includes(playerIndex)) {
              finishers.push(playerIndex);
            }
          });
        }

        return {
          ...currentRound,
          finishers
        };
      });
    }

    case AT_ROUND_FINISHED: {
      throwIfNotAllFourFinished(state);
      const {payload} = action;
      const {stichPoints} = payload;

      if (stichPoints[TEAM.one] === 0 && stichPoints[TEAM.two] === 0) {
        throw new Error('Scores must be set');
      }

      // set score to current round
      const modifiedState = modifyCurrentRound(state, (currentRound) => {
        const modifiedRound = {...currentRound};
        modifiedRound.stichPoints = stichPoints;

        // calculate total round score
        const teamOneRoundStatus = getTeamRoundStatus(currentRound, TEAM.one);
        const teamTwoRoundStatus = getTeamRoundStatus(currentRound, TEAM.two);
        modifiedRound.score = {
          [TEAM.one]: calcTotalRoundScore(teamOneRoundStatus, stichPoints[TEAM.one]),
          [TEAM.two]: calcTotalRoundScore(teamTwoRoundStatus, stichPoints[TEAM.two])
        };
        return modifiedRound;
      });

      // start next round
      modifiedState.push(getPristineRoundObject(modifiedState.length + 1));
      return modifiedState;
    }

    case AT_UNDO:
      return undoRoundsReducer(state, action);

    case AT_EXITED: {
      return [];
    }

    default:
      return state;
  }
}

/**
 * extracted all logic for undoing actions
 * @param state
 * @param action
 */
function undoRoundsReducer(state: Round[] = [], action: AnyAction): Round[] {
  switch (action.payload.undoActionType) {
    case AT_TICHU_SAID: {
      return modifyCurrentRound(state, (currentRound) => {
        currentRound.tichuSaid.pop(); // remove last element
        return {
          ...currentRound,
          tichuSaid: [...currentRound.tichuSaid]
        };
      });
    }

    case AT_GREAT_TICHU_SAID: {
      return modifyCurrentRound(state, (currentRound) => {
        currentRound.greatTichuSaid.pop(); // remove last element
        return {
          ...currentRound,
          greatTichuSaid: [...currentRound.greatTichuSaid]
        };
      });
    }

    case AT_PLAYER_FINISHED: {
      return modifyCurrentRound(state, (currentRound) => {
        if (currentRound.finishers.length === 4 && hasRoundDoublewin(currentRound)) {
          // The AT_PLAYER_FINISHED ACTION of the 2nd finisher led to all four players being done
          // So, we have to remove all but the first, in order to undo
          currentRound.finishers.pop();
          currentRound.finishers.pop();
          currentRound.finishers.pop();
        } else if (currentRound.finishers.length === 4) {
          // One AT_PLAYER_FINISHED ACTION of the 3rd finisher led to all four players being done
          // So, we have to remove the fourth and third finisher, in order to undo
          currentRound.finishers.pop();
          currentRound.finishers.pop();
        } else {
          // the default case (first finisher or 2nd finisher without doppelsieg), remove the player again to undo
          currentRound.finishers.pop();
        }

        return {
          ...currentRound,
          finishers: [...currentRound.finishers]
        };
      });
    }

    default:
      return state;
  }
}

function modifyCurrentRound(rounds: Round[], modifier: (currentRound: Round) => Round): Round[] {
  const currentRound = getCurrentRoundOrThrow(rounds);
  const modifiedCurrentRound = modifier(currentRound);
  return [...rounds.slice(0, rounds.length - 1), modifiedCurrentRound];
}

function getCurrentRoundOrThrow(rounds: Round[] | undefined): Round {
  const currentRound = rounds && rounds.length ? rounds[rounds.length - 1] : undefined;
  if (!currentRound) {
    throw new Error(
      'Game has not started -> no current round -> cannot modify current round! UI must prevent this!'
    );
  }
  return currentRound;
}

function throwIfSomebodyFinished(rounds: Round[] | undefined): void {
  const currentRound = getCurrentRoundOrThrow(rounds);
  if (currentRound.finishers.length > 0) {
    throw new Error('There are already finishers in this round!');
  }
}

function throwIfNotAllFourFinished(rounds: Round[] | undefined): void {
  const currentRound = getCurrentRoundOrThrow(rounds);
  if (currentRound.finishers.length !== 4) {
    throw new Error('This current round is not yet finished (all four players)');
  }
}

function throwIfInvalidPlayerIndex(playerIndex: number): void {
  if (playerIndex === undefined || playerIndex < 0 || playerIndex > 3) {
    throw new Error('Invalid playerIndex ' + playerIndex);
  }
}
