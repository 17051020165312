import {TichuState} from './state';
import {Round, RoundScore, TEAM} from './roundsReducer';
import {Player} from './playersReducer';
import {UndoItem} from './undoReducer';

export const getCurrentGameId = (state: TichuState): string => state.gameId;
export const getPlayers = (state: TichuState): Player[] => state.players;
export const getRounds = (state: TichuState): Round[] => state.rounds;

export const getRoundsLastRoundFirst = (state: TichuState): Round[] => {
  const rounds = [...getRounds(state)];
  return rounds.reverse();
};

export const getRoundSaldiLastRoundFirst = (state: TichuState): RoundScore[] => {
  const saldi: RoundScore[] = getRounds(state).reduce((acc: RoundScore[], round) => {
    if (round.roundNumber === 1) {
      acc.push(round.score);
    } else {
      const newSaldoScore = {
        [TEAM.one]: acc[acc.length - 1][TEAM.one] + round.score[TEAM.one],
        [TEAM.two]: acc[acc.length - 1][TEAM.two] + round.score[TEAM.two]
      };
      acc.push(newSaldoScore);
    }

    return acc;
  }, []);

  saldi.reverse();
  return saldi;
};

export const getTotalScore = (state: TichuState): RoundScore => {
  if (state.rounds.length === 0) {
    return {
      [TEAM.one]: 0,
      [TEAM.two]: 0
    };
  }

  return {
    [TEAM.one]: state.rounds.reduce((acc, round) => acc + round.score[TEAM.one], 0),
    [TEAM.two]: state.rounds.reduce((acc, round) => acc + round.score[TEAM.two], 0)
  };
};

export const getCurrentRound = (state: TichuState): Round | undefined => {
  if (state.rounds.length) {
    return state.rounds[state.rounds.length - 1];
  }

  return undefined;
};

export const getUndoItems = (state: TichuState): UndoItem[] => {
  if (state.undo.length) {
    return state.undo;
  }

  return [];
};

export const getCurrentRoundNumber = (state: TichuState): number => {
  if (state.rounds.length) {
    return state.rounds.length;
  } else {
    return 0;
  }
};

export interface TeamRoundStatus {
  isDoubleWin: boolean;

  hasFailedTichu: boolean;
  hasSucceededTichu: boolean;
  failedTichuPlayerIndex: number; // can be -1 if hasFailedTichu is false
  succeededTichuPlayerIndex: number; // can be -1 if hasSucceededTichu is false

  hasFailedGreatTichu: boolean;
  hasSucceededGreatTichu: boolean;
  failedGreatTichuPlayerIndex: number; // can be -1 if hasFailedGreatTichu is false
  succeededGreatTichuPlayerIndex: number; // can be -1 if hasSucceededGreatTichu is false

  isTeamFirstFinisher: boolean;
}

export const getTeamRoundStatus = (currentRound: Round, team: TEAM): TeamRoundStatus => {
  let teamPlayerIndexOne;
  let teamPlayerIndexTwo;

  if (team === TEAM.one) {
    teamPlayerIndexOne = 0;
    teamPlayerIndexTwo = 3;
  } else {
    teamPlayerIndexOne = 1;
    teamPlayerIndexTwo = 2;
  }

  const plRsOne = getPlayerRoundStatus(currentRound, teamPlayerIndexOne);
  const plRsTwo = getPlayerRoundStatus(currentRound, teamPlayerIndexTwo);

  return {
    isDoubleWin: plRsOne.isDoubleWin, // isDoubleWin is the same for both players

    hasFailedTichu: plRsOne.hasFailedTichu || plRsTwo.hasFailedTichu,
    hasSucceededTichu: plRsOne.hasSucceededTichu || plRsTwo.hasSucceededTichu,
    failedTichuPlayerIndex: plRsOne.hasFailedTichu
      ? plRsOne.playerIndex
      : plRsTwo.hasFailedTichu
      ? plRsTwo.playerIndex
      : -1,
    succeededTichuPlayerIndex: plRsOne.hasSucceededTichu
      ? plRsOne.playerIndex
      : plRsTwo.hasSucceededTichu
      ? plRsTwo.playerIndex
      : -1,

    hasFailedGreatTichu: plRsOne.hasFailedGreatTichu || plRsTwo.hasFailedGreatTichu,
    hasSucceededGreatTichu: plRsOne.hasSucceededGreatTichu || plRsTwo.hasSucceededGreatTichu,
    failedGreatTichuPlayerIndex: plRsOne.hasFailedGreatTichu
      ? plRsOne.playerIndex
      : plRsTwo.hasFailedGreatTichu
      ? plRsTwo.playerIndex
      : -1,
    succeededGreatTichuPlayerIndex: plRsOne.hasSucceededGreatTichu
      ? plRsOne.playerIndex
      : plRsTwo.hasSucceededGreatTichu
      ? plRsTwo.playerIndex
      : -1,

    isTeamFirstFinisher: plRsOne.isFirstFinisher || plRsTwo.isFirstFinisher
  };
};

interface PlayerRoundStatus {
  playerIndex: number;
  hasFinished: boolean;
  finisherIndex: number;
  isFirstFinisher: boolean;
  isDoubleWin: boolean;

  hasSaidTichu: boolean;
  hasFailedTichu: boolean;
  hasSucceededTichu: boolean;

  hasSaidGreatTichu: boolean;
  hasFailedGreatTichu: boolean;
  hasSucceededGreatTichu: boolean;
}

export const getPlayerRoundStatus = (
  currentRound: Round,
  playerIndex: number
): PlayerRoundStatus => {
  const somebodyFinished = currentRound.finishers.length > 0;
  const hasSaidTichu = currentRound.tichuSaid.includes(playerIndex);
  const hasSaidGreatTichu = currentRound.greatTichuSaid.includes(playerIndex);
  const finisherIndex = currentRound.finishers.indexOf(playerIndex);
  const hasFinished = finisherIndex > -1;
  const isFirstFinisher = finisherIndex === 0;
  const hasFailedTichu = hasSaidTichu && somebodyFinished && !isFirstFinisher;
  const hasSucceededTichu = hasSaidTichu && isFirstFinisher;
  const hasFailedGreatTichu = hasSaidGreatTichu && somebodyFinished && !isFirstFinisher;
  const hasSucceededGreatTichu = hasSaidGreatTichu && isFirstFinisher;

  // Doppelsieg ?
  const partnerPlayerIndex = partnerIndices[playerIndex];
  const partnerFinisherIndex = currentRound.finishers.indexOf(partnerPlayerIndex);
  const isDoubleWin =
    currentRound.finishers.length > 1 &&
    (finisherIndex === 0 || finisherIndex === 1) &&
    (partnerFinisherIndex === 0 || partnerFinisherIndex === 1);

  return {
    playerIndex,
    hasSaidTichu,
    hasSaidGreatTichu,
    hasFinished,
    finisherIndex,
    isFirstFinisher,
    isDoubleWin,
    hasFailedTichu,
    hasSucceededTichu,
    hasFailedGreatTichu,
    hasSucceededGreatTichu
  };
};

const partnerIndices = [3, 2, 1, 0];

/**
 * Pass in a Round, will return true if the round has "Doppelsieg".
 * - at least two finishers
 * - first and second finisher belong to same team
 */
export const hasRoundDoublewin = (round: Round): boolean => {
  if (round.finishers.length < 2) {
    return false;
  }

  const firstFinisherPLI = round.finishers[0];
  const secondFinisherPLI = round.finishers[1];

  return partnerIndices[firstFinisherPLI] === secondFinisherPLI;
};
