import React from 'react';
import {useSelector} from 'react-redux';

import Header from '../header/Header';
import Button from '../common/Button';
import {
  getPlayers,
  getRoundSaldiLastRoundFirst,
  getRoundsLastRoundFirst,
  getTeamRoundStatus,
  TeamRoundStatus
} from '../state/selectors';
import {Round, RoundScore, TEAM} from '../state/roundsReducer';
import {StyledMain, StyledViewWrapper} from '../common/_styled';
import {StyledFlexTable, StyledFlexTableCell, StyledTeamRoundCell} from './_styled';
import {Player} from '../state/playersReducer';

type GameLogProps = {
  gameId: string;
  onSwitchToGameView: () => void;
};

const GameLog = ({gameId, onSwitchToGameView}: GameLogProps) => {
  const rounds = useSelector(getRoundsLastRoundFirst);
  const roundsSaldi = useSelector(getRoundSaldiLastRoundFirst);
  const players = useSelector(getPlayers);

  return (
    <StyledViewWrapper x-game-id={gameId}>
      <Header onDragonClick={onSwitchToGameView}>
        <div>Verlauf</div>
        <Button onClick={onSwitchToGameView}>Zrugg</Button>
      </Header>

      <StyledMain>
        <StyledFlexTable>
          <StyledFlexTableCell>
            <h2>#</h2>
          </StyledFlexTableCell>
          <StyledFlexTableCell>
            <h2>
              {players[0].name} & {players[3].name}
            </h2>
          </StyledFlexTableCell>
          <StyledFlexTableCell>
            <h2>
              {players[1].name} & {players[2].name}
            </h2>
          </StyledFlexTableCell>

          {rounds.map((round, index) => (
            <GameLogRound
              players={players}
              round={round}
              key={`gamelog:round:${round.roundNumber}`}
              saldo={roundsSaldi[index]}
            />
          ))}
        </StyledFlexTable>
      </StyledMain>
    </StyledViewWrapper>
  );
};

type GameLogRoundProps = {
  round: Round;
  players: Player[];
  saldo: RoundScore;
};
const GameLogRound = ({players, round, saldo}: GameLogRoundProps) => {
  const isFinished = round.finishers.length === 4;

  const teamOneRoundStatus = getTeamRoundStatus(round, TEAM.one);
  const teamTwoRoundStatus = getTeamRoundStatus(round, TEAM.two);

  return (
    <>
      {isFinished && (
        <>
          <StyledFlexTableCell>
            <h3>{round.roundNumber}</h3>
          </StyledFlexTableCell>
          <TeamRoundCell
            players={players}
            teamRoundStatus={teamOneRoundStatus}
            teamRoundSaldo={saldo[TEAM.one]}
            stichPoints={round.stichPoints[TEAM.one]}
          />
          <TeamRoundCell
            players={players}
            teamRoundStatus={teamTwoRoundStatus}
            teamRoundSaldo={saldo[TEAM.two]}
            stichPoints={round.stichPoints[TEAM.two]}
          />
        </>
      )}

      {!isFinished && (
        <>
          <StyledFlexTableCell>
            <h3>{round.roundNumber}</h3>
          </StyledFlexTableCell>
          <StyledFlexTableCell />
          <StyledFlexTableCell />
        </>
      )}
    </>
  );
};

export default GameLog;

type TeamRoundCellProps = {
  players: Player[];
  teamRoundStatus: TeamRoundStatus;
  stichPoints: number;
  teamRoundSaldo: number;
};

const TeamRoundCell = ({
  players,
  teamRoundStatus,
  teamRoundSaldo,
  stichPoints
}: TeamRoundCellProps) => (
  <StyledTeamRoundCell>
    <div>
      <h4>{teamRoundSaldo}</h4>
    </div>

    {teamRoundStatus.isDoubleWin && (
      <div>
        <i className="icon icon-calc" />
        <span>DS 200</span>
      </div>
    )}

    {!teamRoundStatus.isDoubleWin && (
      <div>
        <i className="icon icon-calc" />
        <span>{stichPoints}</span>
      </div>
    )}

    {teamRoundStatus.hasSucceededTichu && (
      <div className="tch-succeeded ">
        <i className="icon icon-ok-circled" />
        <span>T +100 {players[teamRoundStatus.succeededTichuPlayerIndex]?.name}</span>
      </div>
    )}
    {teamRoundStatus.hasFailedTichu && (
      <div className="tch-failed">
        <i className="icon icon-cancel-circled" />
        <span>T -100 {players[teamRoundStatus.failedTichuPlayerIndex]?.name}</span>
      </div>
    )}

    {teamRoundStatus.hasSucceededGreatTichu && (
      <div className="tch-succeeded ">
        <i className="icon icon-ok-circled" />
        <span>GT +200 {players[teamRoundStatus.succeededGreatTichuPlayerIndex]?.name}</span>
      </div>
    )}
    {teamRoundStatus.hasFailedGreatTichu && (
      <div className="tch-failed">
        <i className="icon icon-cancel-circled" />
        <span>GT -200 {players[teamRoundStatus.failedGreatTichuPlayerIndex]?.name}</span>
      </div>
    )}
  </StyledTeamRoundCell>
);
