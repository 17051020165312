import React, {useEffect, useState} from 'react';

import Button from '../common/Button';

import {StyledStichScoreSelector, StyledStichScoreSelectorRow} from './_styled';

type StichScoreSelectorProps = {
  value: number;
  onChange: (value: number) => void;
};

const StichScoreSelector = ({value, onChange}: StichScoreSelectorProps) => {
  const [isMinus, setIsMinus] = useState(false);
  const [zehner, setZehner] = useState<number>(0);
  const [withFive, setWithFive] = useState(false);

  useEffect(() => {
    const im = value < 0 || (isMinus && value === 0);
    const z = Math.floor(Math.abs(value) / 10) * 10;
    const wf = Math.abs(value) % 10 === 5;
    setIsMinus(im);
    setZehner(z);
    setWithFive(wf);
  }, [value, isMinus]);

  const ZehnerButton = ({v}: any) => (
    <Button onClick={() => onValueClicked(v)} selected={zehner === v}>
      {v}
    </Button>
  );

  return (
    <StyledStichScoreSelector>
      <StyledStichScoreSelectorRow>
        <ZehnerButton v={10} />
        <ZehnerButton v={20} />
        <ZehnerButton v={30} />
        <ZehnerButton v={40} />
        <ZehnerButton v={50} />
        <ZehnerButton v={60} />
      </StyledStichScoreSelectorRow>
      <StyledStichScoreSelectorRow>
        <ZehnerButton v={70} />
        <ZehnerButton v={80} />
        <ZehnerButton v={90} />
        <ZehnerButton v={100} />
      </StyledStichScoreSelectorRow>
      <StyledStichScoreSelectorRow>
        <Button onClick={onMinus} selected={isMinus}>
          -
        </Button>
        <Button onClick={() => onFive()} selected={withFive}>
          5
        </Button>
      </StyledStichScoreSelectorRow>
    </StyledStichScoreSelector>
  );

  function onMinus() {
    setIsMinus(!isMinus);
    onChange((!isMinus ? -1 : 1) * (zehner + (withFive ? 5 : 0)));
  }

  function onValueClicked(value: number) {
    if (value === zehner) {
      setZehner(0);
      onChange((isMinus ? -1 : 1) * (0 + (withFive ? 5 : 0)));
    } else {
      setZehner(value);
      onChange((isMinus ? -1 : 1) * (value + (withFive ? 5 : 0)));
    }
  }

  function onFive() {
    setWithFive(!withFive);
    onChange((isMinus ? -1 : 1) * (zehner + (!withFive ? 5 : 0)));
  }
};

export default StichScoreSelector;
