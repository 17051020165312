import styled from 'styled-components/macro';
import {COL_BORDER} from '../colors';
import tichuPersonSmall from '../resources/tichu_person_transparent_32.png';
import {StyledButton} from '../common/_styled';

export const StyledStichScoreSelector = styled.div`
  margin-bottom: 4px;
`;

export const StyledStichScoreSelectorRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    width: 15%;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  ${StyledButton} {
    padding: 4px;
  }
`;

export const StyledTeamWrapper = styled.div`
  h3 {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > div {
      margin-left: 8px;
    }
  }

  padding: 8px 4px;
  border: 1px solid ${COL_BORDER};
  border-radius: 8px;

  margin: 16px 0;
`;

interface StyledRoundScoreRowProps {
  border?: boolean;
}

export const StyledRoundScoreRow = styled.div<StyledRoundScoreRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 4px;

  border-bottom: 1px solid ${(props) => (props.border ? COL_BORDER : 'transparent')};

  > div {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  > div:nth-child(odd) {
    width: 70%;
  }

  > div:nth-child(even) {
    width: 15%;
  }
`;

export const StyledTeamFirstFinisherBadge = styled.div`
  background: url(${tichuPersonSmall}) no-repeat;
  width: 32px;
  height: 30px;
  background-size: contain;
  display: inline-block;
`;
