import {AnyAction} from 'redux';

import {AT_EXITED, AT_FIRST_ROUND_STARTED} from './actions';

export const MAX_PLAYER_NAME_LENGTH = 12;

export interface Player {
  index: number;
  name: string;
}

export default function playersReducer(state: Player[] = [], action: AnyAction): Player[] {
  switch (action.type) {
    case AT_FIRST_ROUND_STARTED: {
      if (!action.payload.players || action.payload.players.length !== 4) {
        throw new Error('Invalid players array in action payload');
      }

      return action.payload.players;
    }

    case AT_EXITED: {
      return [];
    }

    default:
      return state;
  }
}
