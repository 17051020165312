import isDate from 'date-fns/isDate';

export function normalizeDateOrTs(dateOrTs: number | Date) {
  if (isDate(dateOrTs)) {
    return dateOrTs;
  }

  const tsAsNumber = dateOrTs as number;
  if (tsAsNumber / 1000000000 > 10) {
    return new Date(tsAsNumber);
  } else {
    return new Date(tsAsNumber * 1000);
  }
}
