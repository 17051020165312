import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Landing from './landing/Landing';
import Game from './game/Game';
import {getCurrentGameId, getCurrentRoundNumber} from './state/selectors';
import {aNewGameId} from './state/actions';
import getBrowserHistory from './utils/getBrowserHistory';
import RoundScore from './roundScore/RoundScore';
import GameLog from './gameLog/GameLog';

const history = getBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const gameId = useSelector(getCurrentGameId);
  useGameIdToHistoryStateSync(gameId);

  const roundNumber = useSelector(getCurrentRoundNumber);
  useEffect(() => {
    setShowRoundScoreView(false);
  }, [roundNumber]);
  const [showRoundScoreView, setShowRoundScoreView] = useState(false);
  const [showGameLogView, setShowGameLogView] = useState(false);

  if (gameId && showGameLogView) {
    return (
      <div>
        <GameLog gameId={gameId} onSwitchToGameView={() => setShowGameLogView(false)} />
      </div>
    );
  }

  if (gameId && showRoundScoreView) {
    return (
      <div>
        <RoundScore gameId={gameId} onSwitchToGameView={() => setShowRoundScoreView(false)} />
      </div>
    );
  }

  if (gameId) {
    return (
      <div>
        <Game
          gameId={gameId}
          onSwitchToRoundScoreView={() => setShowRoundScoreView(true)}
          onSwitchToGameLogView={() => setShowGameLogView(true)}
        />
      </div>
    );
  }

  return (
    <div>
      <Landing onNewGame={onNewGame} />
    </div>
  );

  function onNewGame() {
    dispatch(aNewGameId());
  }
};

export default App;

function useGameIdToHistoryStateSync(gameId: string) {
  useEffect(() => {
    history.push(`/${gameId}`);
  }, [gameId]);
}
