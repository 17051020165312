import styled from 'styled-components/macro';
import {
  COL_BUTTON_BACKGROUND,
  COL_BUTTON_DISABLED_BACKGROUND,
  COL_BUTTON_BORDER,
  COL_BORDER,
  COL_BUTTON_PRIMARY_BORDER,
  COL_PRIMARY,
  COL_FONT,
  COL_HIGHLIGHTED
} from '../colors';

export const StyledPullCenter = styled.div`
  display: flex;
  justify-content: center;
`;

interface StyledButtonProps {
  selected?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: 1px solid
    ${(props) =>
      props.disabled ? COL_BUTTON_BORDER : props.selected ? COL_HIGHLIGHTED : COL_BUTTON_BORDER};
  background: ${(props) =>
    props.disabled ? COL_BUTTON_DISABLED_BACKGROUND : COL_BUTTON_BACKGROUND};
  color: ${(props) => (props.disabled ? COL_BORDER : props.selected ? COL_HIGHLIGHTED : COL_FONT)};
  border-radius: 8px;
  padding: 6px;
`;

export const StyledButtonPrimary = styled(StyledButton)`
  border: 1px solid ${(props) => (props.disabled ? COL_BUTTON_BORDER : COL_BUTTON_PRIMARY_BORDER)};
  background: ${(props) => (props.disabled ? COL_BUTTON_DISABLED_BACKGROUND : COL_PRIMARY)};
  color: ${(props) => (props.disabled ? COL_BORDER : 'white')};
`;

export const StyledViewWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledMain = styled.div`
  padding: 4px;
`;
