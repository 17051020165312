import styled from 'styled-components/macro';
import {StyledButton} from '../common/_styled';

export const StyledLandingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledMainArea = styled.div`
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMiniButton = styled(StyledButton)`
  font-size: 0.8rem;
  padding: 3px 5px;
  margin-bottom: 8px;
`;

export const StyledGameList = styled.ul`
  display: block;
  margin-bottom: 24px;

  li {
    list-style-type: none;
    margin-bottom: 4px;

    a {
      text-decoration: none;
      color: white;
    }
  }
`;
