// base
export const COL_BACKGROUND_DARK = '#0d1117';
export const COL_BACKGROUND_DARK_TWO = '#161B22FF';
export const COL_FONT = '#C9D1D9FF';
export const COL_BORDER = '#4b5258';
export const COL_HIGHLIGHTED = '#58a6ff';
export const COL_PRIMARY = '#238636';

// buttons
export const COL_BUTTON_PRIMARY_BORDER = 'rgba(240, 246, 252, 0.1)';
export const COL_BUTTON_BORDER = 'rgba(240, 246, 252, 0.1)';
export const COL_BUTTON_BACKGROUND = '#21262DFF';
export const COL_BUTTON_DISABLED_BACKGROUND = 'rgba(33,38,45,0.37)';

// input fields
export const COL_INPUT_BACKGROUND = '#010409';
export const COL_INPUT_BORDER = '#30363d';

// markers for TICHU suceeded or failed
export const COL_TICHU_FAILED_BORDER = '#ff4d00';
export const COL_TICHU_FAILED_FONT = '#ffa068';
export const COL_TICHU_SUCCEEDED_BORDER = '#45980b';
export const COL_TICHU_SUCCEEDED_FONT = '#a4cc89';
