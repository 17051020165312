import getGameIdFromUrl from './getGameIdFromUrl';
import {TichuState} from '../state/state';
import {getCurrentGameId} from '../state/selectors';

export interface GameListItem {
  id: string;
  roundCount: number;
  gameStartTimestamp: number; // unix timestamp in seconds
}

const LSTORAGE_PROP_PREFIX = 'tch:';

/**
 * remove all our data from localStorage
 */
export const clearPersistedState = () => {
  localStorage.clear();
};

export const listPersistedGames = () => {
  const allGames: GameListItem[] = [];
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    const lsKey = localStorage.key(i);
    if (lsKey && lsKey.substring(0, LSTORAGE_PROP_PREFIX.length) === LSTORAGE_PROP_PREFIX) {
      const serializedValue: string | null = localStorage.getItem(lsKey);
      if (serializedValue) {
        try {
          const parsedValue: unknown = JSON.parse(serializedValue);
          const tmpGame: TichuState = parsedValue as TichuState;
          allGames.push({
            id: tmpGame.gameId,
            gameStartTimestamp: tmpGame.gameMeta.gameStartTimestamp,
            roundCount: tmpGame.rounds.length
          });
        } catch (e) {
          console.error(e);
          console.warn(` could not load persisted games`);
        }
      }
    }
  }

  return allGames;
};

/**
 *
 * @param state
 */
export function setPersistedState(state: TichuState): void {
  const currentGameId = getCurrentGameId(state);
  if (!currentGameId) {
    return;
  }

  localStorage.setItem(`${LSTORAGE_PROP_PREFIX}${currentGameId}`, JSON.stringify(state));
}

/**
 *
 */
export function getPersistedStateMatchingUrlGameId(): TichuState | undefined {
  const presetGameId = getGameIdFromUrl();

  if (!presetGameId) {
    return undefined;
  }

  const persistedState = localStorage.getItem(`${LSTORAGE_PROP_PREFIX}${presetGameId}`);
  if (!persistedState) {
    return undefined;
  }

  try {
    return JSON.parse(persistedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
