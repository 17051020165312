import React from 'react';

import {StyledButton, StyledButtonPrimary} from './_styled';

type ButtonProps = {
  disabled?: boolean;
  selected?: boolean;
  primary?: boolean;
  onClick: () => void;
  children?: string | JSX.Element | JSX.Element[] | any[];
  style?: object;
};

const Button = ({
  onClick,
  children,
  disabled = false,
  selected = false,
  primary = false,
  style = {}
}: ButtonProps) => {
  if (primary) {
    return (
      <StyledButtonPrimary
        type="button"
        onClick={onClick}
        disabled={disabled}
        selected={selected}
        style={style}
      >
        {children}
      </StyledButtonPrimary>
    );
  } else {
    return (
      <StyledButton
        type="button"
        onClick={onClick}
        disabled={disabled}
        selected={selected}
        style={style}
      >
        {children}
      </StyledButton>
    );
  }
};

export default Button;
