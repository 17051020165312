import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getCurrentRound,
  getCurrentRoundNumber,
  getPlayers,
  getTeamRoundStatus,
  getTotalScore,
  TeamRoundStatus
} from '../state/selectors';
import {aFinishRound} from '../state/actions';
import {TEAM} from '../state/roundsReducer';
import {Player} from '../state/playersReducer';
import StichScoreSelector from './StichScoreSelector';
import Header from '../header/Header';
import Button from '../common/Button';
import {calcTotalRoundScore, DOUBLE_WIN_POINTS, STICH_POINT_TOTAL} from '../utils/gameRuleHelper';

import {StyledPullCenter} from '../common/_styled';
import {StyledTeamWrapper, StyledRoundScoreRow, StyledTeamFirstFinisherBadge} from './_styled';
import {StyledViewWrapper, StyledMain} from '../common/_styled';

type PointsProps = {
  gameId: string;
  onSwitchToGameView: () => void;
};

/**
 * View that displays round summary (tichu, great tichu, doubleWin) and allows to select the stich points/score per team.
 */
const RoundScore = ({gameId, onSwitchToGameView}: PointsProps) => {
  const dispatch = useDispatch();
  const currentRound = useSelector(getCurrentRound);
  const roundNumber = useSelector(getCurrentRoundNumber);
  const players = useSelector(getPlayers);
  const totalScore = useSelector(getTotalScore);

  const [stichPointsTeamOne, setStichPointsTeamOne] = useState(0);
  const [stichPointsTeamTwo, setStichPointsTeamTwo] = useState(0);

  if (!currentRound || currentRound.finishers.length !== 4) {
    return null;
  }

  const teamOneRoundStatus = getTeamRoundStatus(currentRound, TEAM.one);
  const teamTwoRoundStatus = getTeamRoundStatus(currentRound, TEAM.two);

  const totalRoundScoreTeamOne = calcTotalRoundScore(teamOneRoundStatus, stichPointsTeamOne);
  const totalRoundScoreTeamTwo = calcTotalRoundScore(teamTwoRoundStatus, stichPointsTeamTwo);

  const hasRoundDoubleWin = teamOneRoundStatus.isDoubleWin || teamTwoRoundStatus.isDoubleWin;
  const preventFinishRound = !(
    hasRoundDoubleWin ||
    stichPointsTeamOne !== 0 ||
    stichPointsTeamTwo !== 0
  );

  return (
    <StyledViewWrapper x-game-id={gameId}>
      <Header>
        <div>{totalScore[TEAM.one]}</div>
        <div>{roundNumber > 0 && `Rundi:${roundNumber}`}</div>
        <div>{totalScore[TEAM.two]}</div>
        <Button onClick={onSwitchToGameView}>Zrugg</Button>
      </Header>

      <StyledMain>
        <TeamRoundScore
          teamRoundStatus={teamOneRoundStatus}
          totalRoundScore={totalRoundScoreTeamOne}
          hasRoundDoubleWin={hasRoundDoubleWin}
          stichPoints={stichPointsTeamOne}
          onSetStichPoints={(points) => setStichPoints(TEAM.one, points)}
          players={[players[0], players[3]]}
          roundNumber={roundNumber}
        />

        <TeamRoundScore
          teamRoundStatus={teamTwoRoundStatus}
          totalRoundScore={totalRoundScoreTeamTwo}
          hasRoundDoubleWin={hasRoundDoubleWin}
          stichPoints={stichPointsTeamTwo}
          onSetStichPoints={(points) => setStichPoints(TEAM.two, points)}
          players={[players[1], players[2]]}
          roundNumber={roundNumber}
        />

        <StyledPullCenter>
          <Button primary={true} onClick={onFinishRoundClick} disabled={preventFinishRound}>
            Rundi beändä
          </Button>
        </StyledPullCenter>
      </StyledMain>
    </StyledViewWrapper>
  );

  function setStichPoints(team: TEAM, points: number) {
    if (team === TEAM.one) {
      setStichPointsTeamOne(points);
      setStichPointsTeamTwo(STICH_POINT_TOTAL - points);
    } else {
      setStichPointsTeamOne(STICH_POINT_TOTAL - points);
      setStichPointsTeamTwo(points);
    }
  }

  function onFinishRoundClick() {
    if (preventFinishRound) {
      return;
    }

    let finishRoundAction;

    if (teamOneRoundStatus.isDoubleWin) {
      finishRoundAction = aFinishRound({
        [TEAM.one]: DOUBLE_WIN_POINTS,
        [TEAM.two]: 0
      });
    } else if (teamTwoRoundStatus.isDoubleWin) {
      finishRoundAction = aFinishRound({
        [TEAM.one]: 0,
        [TEAM.two]: DOUBLE_WIN_POINTS
      });
    } else {
      finishRoundAction = aFinishRound({
        [TEAM.one]: stichPointsTeamOne,
        [TEAM.two]: stichPointsTeamTwo
      });
    }

    dispatch(finishRoundAction);
  }
};

export default RoundScore;

type TeamRoundScoreProps = {
  teamRoundStatus: TeamRoundStatus;
  players: Player[]; // just the two players of this team (not all four)
  roundNumber: number;
  stichPoints: number;
  totalRoundScore: number;
  onSetStichPoints: (points: number) => void;
  hasRoundDoubleWin: boolean;
};

const TeamRoundScore = ({
  players,
  teamRoundStatus,
  roundNumber,
  stichPoints,
  totalRoundScore,
  onSetStichPoints,
  hasRoundDoubleWin
}: TeamRoundScoreProps) => {
  return (
    <StyledTeamWrapper>
      <h3>
        {players[0].name} & {players[1].name}
        {teamRoundStatus.isTeamFirstFinisher && <StyledTeamFirstFinisherBadge />}
      </h3>

      <StyledRoundScoreRow border={true}>
        <div>Rundi:{roundNumber} Total</div>
        <div>{totalRoundScore}</div>
      </StyledRoundScoreRow>

      <StyledRoundScoreRow>
        {teamRoundStatus.hasSucceededTichu && (
          <>
            <div>Tichu gmacht</div>
            <div>+100</div>
          </>
        )}
        {teamRoundStatus.hasFailedTichu && (
          <>
            <div>Tichu aagseit, aber nöd gmacht</div>
            <div>-100</div>
          </>
        )}

        {teamRoundStatus.hasSucceededGreatTichu && (
          <>
            <div>Grosses Tichu gmacht</div>
            <div>+200</div>
          </>
        )}
        {teamRoundStatus.hasFailedGreatTichu && (
          <>
            <div>Grosses Tichu aagseit, aber nöd gmacht</div>
            <div>-200</div>
          </>
        )}

        {teamRoundStatus.isDoubleWin && (
          <>
            <div>Doppelsieg</div>
            <div>+200</div>
          </>
        )}
      </StyledRoundScoreRow>

      {!hasRoundDoubleWin && (
        <>
          <StyledRoundScoreRow>
            <div>Stich</div>
            <div>{stichPoints}</div>
          </StyledRoundScoreRow>
          <StichScoreSelector value={stichPoints} onChange={onSetStichPoints} />
        </>
      )}
    </StyledTeamWrapper>
  );
};
