import styled from 'styled-components/macro';

import {COL_BACKGROUND_DARK_TWO, COL_FONT} from '../colors';
import {HEADER_HEIGHT} from '../header/_styled';

export const StyledMenu = styled.div`
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  padding: 4px;
  background: ${COL_BACKGROUND_DARK_TWO};

  box-shadow: 0 0 17px rgb(0 0 0 / 69%);

  ul {
    li {
      list-style: none;
      padding: 8px 4px;

      a,
      a:link,
      a:visited {
        text-decoration: none;
        color: ${COL_FONT};
      }
    }
  }
`;
