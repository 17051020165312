import styled from 'styled-components/macro';
import {
  COL_BUTTON_BACKGROUND,
  COL_INPUT_BACKGROUND,
  COL_BORDER,
  COL_INPUT_BORDER,
  COL_FONT,
  COL_HIGHLIGHTED,
  COL_TICHU_SUCCEEDED_BORDER,
  COL_TICHU_FAILED_BORDER,
  COL_TICHU_FAILED_FONT,
  COL_TICHU_SUCCEEDED_FONT
} from '../colors';
import {StyledButton} from '../common/_styled';
import tichuChineseImage from '../resources/tichu_person_transparent.png';
import tichuNumbers1to4 from '../resources/numbers_1-4_inverted.png';

export const StyledPlayersWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: [left] 50% [right] 50%;
  grid-template-rows: [top] 50% [bottom] 50%;
`;

export const StyledGameSetupWrapper = styled.div`
  padding: 8px;
`;

export const StyledPlayersList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
`;

interface StyledPlayersListBoxProps {
  index: number;
}

export const StyledPlayersListBox = styled.div<StyledPlayersListBoxProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.index === 0 || props.index == 2
      ? '5px [center] auto 5px [right] 30px'
      : '[left] 30px 5px [center] auto 5px'};
  grid-template-rows: ${(props) =>
    props.index === 0 || props.index === 1
      ? '5px [center] auto 5px [bottom] 30px'
      : '[top] 30px 5px [center] auto 5px'};
  width: 49%;
  padding: 5px;
  border: 1px solid ${COL_BORDER};
  border-radius: 8px;
  margin-bottom: 8px;

  button {
    padding: 0;
  }
`;

export const StyledTeams = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 24px 0;
`;

export const StyledTeamName = styled.div`
  text-align: center;

  margin-right: 4px;
`;

export const StyledPlayerNameInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledInput = styled.input`
  background: ${COL_INPUT_BACKGROUND};
  border: 1px solid ${COL_INPUT_BORDER};
  color: ${COL_FONT};
  padding: 4px;
  border-radius: 4px;
  margin-right: 4px;
  width: 100%;
  display: block;
  flex-grow: 1;

  &:focus {
    border: 1px solid ${COL_HIGHLIGHTED};
  }
`;

export const StyledPlayerTileWrapper = styled.div`
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row-start: auto;
  grid-row-end: auto;

  &:nth-child(odd) {
    border-right: 1px solid ${COL_BORDER};
  }

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px solid ${COL_BORDER};
  }

  padding: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  > div {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
  }

  ${StyledButton} {
    font-size: 0.9rem;
  }
`;

export const StyledPlayerName = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
  font-size: 1rem;
`;

export const StyledTichuButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  ${StyledButton} {
    display: block;
    min-height: 5.5rem;
    flex-grow: 1;
    font-size: 0.9rem;
    width: 50%;

    &:nth-child(odd) {
      margin: 0 2px 0 0;
    }

    &:nth-child(even) {
      margin: 0 0 0 2px;
    }
  }
`;

export const StyledFirstFinisherBadge = styled.div`
  background: url(${tichuChineseImage}) no-repeat;
  height: 80px;
  width: 100px;
  background-size: contain;
`;

interface StyledFirstFinisherNumberProps {
  position: number;
}

export const StyledFirstFinisherNumber = styled.div<StyledFirstFinisherNumberProps>`
  background: url(${tichuNumbers1to4}) no-repeat ${(props) => props.position}px 0;
  height: 40px;
  width: 20px;
  background-size: cover;
  margin-right: 8px;
`;

export const StyledFinishStatus = styled.div`
  margin: 0.8rem 0;
  justify-content: center;

  > * {
    font-size: 0.9rem;
  }
`;
export const StyledFinisherPlace = styled.div`
  margin: 0.8rem 0;
  display: flex;
  align-items: center;
  height: 80px;
`;

interface StyledClickedTichuButtonProps {
  failed: boolean;
  succeeded: boolean;
}

export const StyledClickedTichuButton = styled.div<StyledClickedTichuButtonProps>`
  border: 1px solid
    ${(props) =>
      props.failed
        ? COL_TICHU_FAILED_BORDER
        : props.succeeded
        ? COL_TICHU_SUCCEEDED_BORDER
        : COL_HIGHLIGHTED};
  background: ${COL_BUTTON_BACKGROUND};
  color: ${(props) =>
    props.failed ? COL_TICHU_FAILED_FONT : props.succeeded ? COL_TICHU_SUCCEEDED_FONT : COL_FONT};
  border-radius: 8px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem;
  width: 50%;

  &:nth-child(odd) {
    margin: 0 2px 0 0;
  }

  &:nth-child(even) {
    margin: 0 0 0 2px;
  }
`;
