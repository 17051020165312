import {nanoid} from 'nanoid';
import {Player} from './playersReducer';
import {RoundScore} from './roundsReducer';

export const AT_TICHU_SAID = 'AT_TICHU_SAID';
export const AT_GREAT_TICHU_SAID = 'AT_GREAT_TICHU_SAID';
export const AT_PLAYER_FINISHED = 'AT_PLAYER_FINISHED';
export const AT_ROUND_FINISHED = 'AT_ROUND_FINISHED';
export const AT_FIRST_ROUND_STARTED = 'AT_FIRST_ROUND_STARTED';
export const AT_NEWGAME_ID_SET = 'AT_NEWGAME_ID_SET';
export const AT_EXITED = 'AT_EXITED';
export const AT_UNDO = 'AT_UNDO';

/**
 * player-setup completed -> start game / first round
 * @param players
 */
export const aStartFirstRound = (players: Player[]) => ({
  type: AT_FIRST_ROUND_STARTED,
  payload: {
    players
  }
});

/**
 * finish a round by specifying the stich-points per team.
 * if one team has "doubleWin", specifiy it as 200. the other team then has 0 respectively.
 *
 * @param stichPoints
 */
export const aFinishRound = (stichPoints: RoundScore) => ({
  type: AT_ROUND_FINISHED,
  payload: {
    stichPoints
  }
});

export const aNewGameId = () => ({
  type: AT_NEWGAME_ID_SET,
  payload: {
    gameId: nanoid()
  }
});

export const aPlayerSaysTichu = (playerIndex: number) => ({
  type: AT_TICHU_SAID,
  payload: {
    playerIndex
  }
});

export const aPlayerSaysGreatTichu = (playerIndex: number) => ({
  type: AT_GREAT_TICHU_SAID,
  payload: {
    playerIndex
  }
});

export const aPlayerFinishes = (playerIndex: number) => ({
  type: AT_PLAYER_FINISHED,
  payload: {
    playerIndex
  }
});

export const aExit = () => ({
  type: AT_EXITED
});

export const aUndo = (actionType: string, payload: any) => ({
  type: AT_UNDO,
  payload: {
    undoActionType: actionType,
    undoActionPayload: payload
  }
});
